/* In your CSS file */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Adjust as needed */
  height: 100%;
  /* Adjust as needed */
  border-radius: 50%;

  transition: all 0.3s ease;
}

.tick-mark {
  color: #17C653;
  /* Adjust color as needed */
  font-size: 24px;
  /* Adjust as needed */
}


/* HTML: <div class="loader"></div> */
.loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #25b09b;
  box-shadow:
    calc(1*var(--d)) calc(0*var(--d)) 0 0,
    calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d)) calc(1*var(--d)) 0 2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
    calc(-1*var(--d)) calc(0*var(--d)) 0 4px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
    calc(0*var(--d)) calc(-1*var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}

@keyframes l27 {
  100% {
    transform: rotate(1turn)
  }
}

.rotate {
  animation: rotation 2s infinite linear;
  color: #17C653 !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.TitleCard {
  font-size: 20px;
  color: #071437 !important;
  font-weight: 600;
  padding-bottom: 5px;
}

.TypoCard {
  color: #99a1b7 !important;
  font-weight: 500 !important;

}

.TitleCardbox {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.TypoCardbox {
  font-size: 14px !important;
}

.Pending {
  text-transform: capitalize !important;

  color: #252f4a !important;
  background-color: #f9f9f9 !important;
}

.public {
  text-transform: capitalize !important;

  color: #1b84ff !important;
  background-color: #e9f3ff !important;
}

.private {
  text-transform: capitalize !important;

  color: #17c653 !important;
  background-color: #dfffea !important;
}


.Completed {
  text-transform: capitalize !important;
  color: #17c653 !important;
  background-color: #dfffea !important;
}

.Pending span.MuiLinearProgress-bar {
  background-color: #252f4a;
}

.In.Processing span.MuiLinearProgress-bar {
  background-color: #1b84ff;
}

.Overdue span.MuiLinearProgress-bar {
  background-color: #f8285a;
}

.Completed span.MuiLinearProgress-bar {
  background-color: #17c653;
}

.Cardbox {
  border: "none";
  padding: 20px;

}

.Cardbox:hover {
  border: 1px solid #1B84FF;
}

.MuiTooltip-arrow {
  background: #FFF;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader1 {
  width: 10px;
  margin-left: 5px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

.state-buttons-container {
  padding: 10px 50px 10px 50px;
}

.rotate180 {
  animation: rotate180 0.3s linear forwards;
  /* adjust duration as needed */
}

.rotateMinus180 {
  animation: rotateMinus180 0.3s linear forwards;
  /* adjust duration as needed */
}

.pwdchange {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.popup-pwd {
  padding: 0px;
}

.pwdchange {
  color: #000;
  /* font-family: Inter; */
  font-size: 22px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: normal;
}

hr.MuiDivider-root.MuiDivider-middle.dividermargin.css-ft4jij-MuiDivider-root {
  margin: 0px;
}

.desc-pop {
  padding: 35px 0px;
}

/* blue */
.servericon-blue {
  padding: 7px 5px;
  background: #eff7fe;
  border-radius: 2px;
  margin-right: 10px;
}

.servericon-blue path {
  color: #1B84FF;
}

.servericon-blue {
  width: 2em !important;
  height: 2em !important;
}


/* pink */
.servericon-pink {
  padding: 7px 5px;
  background: #FFEEF3;
  border-radius: 2px;
  margin-right: 10px;
}

.servericon-pink path {
  color: #f8285a;
}

.servericon-pink {
  width: 2em !important;
  height: 2em !important;
}

/* green */
.servericon-green {
  padding: 7px 5px;
  background: #DFFFEA;
  border-radius: 2px;
  margin-right: 10px;
}

.servericon-green path {
  color: #5FD989;
}

.servericon-green {
  width: 2em !important;
  height: 2em !important;
}


/* grey */
.servericon-grey {
  padding: 7px 5px;
  background: #E0E0E0;
  border-radius: 2px;
  margin-right: 10px;
}

.servericon-grey path {
  color: #666666;
}

.servericon-grey {
  width: 2em !important;
  height: 2em !important;
}

.addevencolor {
  margin: 0px;
}

.addevencolor li {
  padding: 20px 40px 20px 25px;
}

.addevencolor li:nth-child(odd) {
  background-color: #FBFAFA;
  /* Light gray color for odd items */
}

/* Apply styles to even list items */
.addevencolor li:nth-child(even) {
  background-color: #ffffff;
  /* White color for even items */
}

.vertisrcoll {
  height: 690px !important;
  overflow-y: scroll;
}

span.datapop {
  color: #5284FC;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

span.taskpop {
  border-radius: 10px;
  background: #FAF3D3;
  padding: 3px 10px;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 05px;
}

.taskpop svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-74kprf-MuiSvgIcon-root {
  margin-right: 3px;
}

.custom-tooltip {
  background-color: #FFF;
  /* Add any other styles as needed */
}

.pwdcontent {
  color: #9ca5ba !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.popup-pwd .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0px !important;
}

.button-wrapper button {
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 13px 20px;
  border-radius: 10px;
}

.popup-pwd svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 20px;
}

.popup-pwd button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary.MuiIconButton-sizeSmall.css-2rshpx-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
}

.button-wrapper {
  padding: 20px 0 20px 0px !important;
}

.popup-pwd svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-9v5bxy-MuiSvgIcon-root {
  width: 28px;
  height: 28px;
  font-size: 20px !important;
  font-weight: 300 !important;
}

.popup-pwd .css-7t7ao9-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: none !important;
}

.popup-pwd .css-7t7ao9-MuiButtonBase-root-MuiIconButton-root {
  background-color: none !important;
  padding: 0px !important;
}

.popup-pwd input::placeholder {
  color: #B3B1B1;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-pwd:hover {
  border: 1px solid #D4D4D4 !important;
}

.MuiDialogContent-root.css-sst63a-MuiDialogContent-root {
  padding: 0px;
}

.popup-pwd .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 35px;
}


.popup-pwd input {
  border-radius: 5px !important;
  margin-bottom: 10px !important;
  border: 1px solid #D4D4D4 !important;
  background: #F7F7F7 !important;
  margin: 0 !important;
  padding: 7px;
  width: 100% !important;
}

.popup-pwd label {
  color: #000;
  margin-top: 20px;
}

@keyframes rotate180 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateMinus180 {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}



/* input[type="date"]::-webkit-calendar-picker-indicator {

color: #1B84FF; 
fill:#1B84FF;
z-index: 1;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(10%) sepia(100%) saturate(1000%) hue-rotate(175deg);
}



input[type="date"]::before {
  content: url('');
  width: 1em;
  height: 1em;
  display: inline-block;
  position: absolute;
  left: 5px;
  color: #1B84FF;

  pointer-events: none;
}

input[type="date"] {
  position: relative;
  border-radius: 6.18px;
  border: none;
  padding: 10px;
  border-bottom: 1px dashed #1B84FF;
  background: #E9F3FF;
  margin-left: 5px;
  color: #1B84FF;
}

input[type="date"]:focus-visible {
  border-bottom: 1px dashed #1B84FF !important;
}

.workitems .MuiDataGrid-columnHeaders {
  background: #F9F9F9;
}

.iconbox-title {
  display: flex;
}

.projectname-items {
  font-size: 14px !important;
  color: #071437;
}

.projectname-items.margintop {
  margin-bottom: 5px;
}

.iconinner {
  padding: 10px 10px 5px 10px;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 10px;
}

.iconinner svg {
  margin: 0;
  font-size: 38px;
}

.workitems .MuiDataGrid-row {
  max-height: 200px !important;
  display: flex !important;
  align-content: center;
  align-items: center;
  flex-direction: row;
}

.iconbox-title {
  display: flex;
  align-content: center;
  align-items: center;
}

.workitems .MuiDataGrid-row {
  max-height: 180px !important;

  border-bottom: 1px dashed #f1eded !important;

}

.workitems .MuiDataGrid-cell {
  padding: 0 !important;
}

.workitems .MuiDataGrid-cell--withRenderer {
  max-height: 70px !important;
}

.projectheading {
  color: #071437;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.96px;
}

span.id-task {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  color: #A4880A;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.96px;
}

.workitems .MuiDataGrid-cell:focus {
  outline: 0 !important;
}

:focus-visible {
  outline: 0 !important;
}

.taskcolor {
  padding: 6px;
}

.id-task2 {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.id-task2 .MuiSelect-select {
  color: #071437;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.96px;
  padding-left: 0px !important;
}

.taskcolor {
  padding: 6px;
  /* color: #17C653; */
  /* background: #17C653; */
  border-radius: 20px;
}

span.dateassign {
  color: #99A1B7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.52px;
}

.workitems .MuiDataGrid-cell:focus-within {
  outline: solid rgba(25, 118, 210, 0.5) 1px;
  outline-width: 1px;
  outline-offset: -1px;
  outline: none !important;
}

.clockrote {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.clockcolor {

  border-radius: 8px;
}

.clockrote svg {
  color: #FFF;
  padding: 0;
  display: -webkit-box;
}

.clockrote svg:hover {
  color: #FFF;
}

.pageloading {
  background: #FFAD0F;
  padding: 9px 10px !important;
  /* display: inline-flex; */
  min-width: auto !important;
  height: 100% !important;
  /* max-height: 70px !important; */
  margin-left: 10px;
  border-radius: 8px;
}

.pageloading button {
  display: contents;
}

.workitems .MuiDataGrid-columnHeader {
  padding: 0 !important;
}

.roletaskdetails {
  padding: 30px !important;
}

.titledet {
  color: #071437 !important;
  font-family: Inter !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 21.06px;
  /* 119.659% */
  margin: 0px !important;
}
.days{
  border-bottom: 2px solid #78829D69;
  display: flex;
  justify-content: left;
  padding-bottom:10px;
  font-weight: 600;
  font-size: large;
  text-align: left;
  margin: 5px;
  color: #78829D
}
.buttonwrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

/* Add these styles in your CSS file */
/* CustomCalendar.css */
.calendar-container {

  text-align: center;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: bold;
  margin: 50px 0px 10px 0px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  padding: 10px;
  cursor: pointer;
}

.selected {
  background-color: #1B84FF;
  color: white;
}

.empty-day {
  visibility: hidden;
}


.buttonstyle22 {
  background: #e9f3ff !important;
  box-shadow: none !important;
  color: #288aff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-left: 15px !important;
  padding: 10px 15px !important;
}

.buttonstyle {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.MuiDataGrid-virtualScroller {
  overflow-y: auto !important;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  border-bottom: 1px dashed #333;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.Cardbox.css-bhp9pd-MuiPaper-root-MuiCard-root, .css-s18byi {
  cursor: pointer;
}


.css-7sl8ba-MuiPaper-root,.css-19y54vw .css-4hf9h5 {
    width: 16rem !important;
    padding: 1.75rem;
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-left {
  animation: slideLeft 0.2s forwards;
}
