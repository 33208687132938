.project-sub{
     color: #ffff;
    opacity: 0.75;
     font-weight: 500;
   font-size: 1.075rem;
   padding-top: 0.25 rem;
    font-family: 'Inter, Helvetica, sans-serif';
}
.box-project {
    padding: 25px !important;
    border-radius: 8.13px !important;
    /* background-color: #080655 ; */
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03) !important;
    height: 213px !important;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
}

.project-sub{
color: #99A1B7;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20.96px;
padding-top: 5px;
}
.progresss-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #747a9f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.96px;
    padding-bottom: 10px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1j49h7g-MuiPaper-root {
    width: auto;
}
.MuiBox-root.css-1rffedr {
    margin-left: 10px;
}
p.MuiTypography-root.MuiTypography-body1.css-1kr61wd-MuiTypography-root {
    font-size: 17px;
}
.startname {
    color: #F8285A;
    font-family: Inter;
    font-size: 80px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 32.5px;
    letter-spacing: -1.495px;
    
}
.timerreport {
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.css-1luai17-MuiButtonBase-root-MuiButton-root {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1c2ql36-MuiSvgIcon-root {
    font-size: 40px;
    padding: 0 !important;
}
.avatarview{
    justify-content: flex-end;
}